<template>
    <div class="modal-basic">
        <div class="head">
            <p>대화를 시작하시려면</p>
            <p>대화 이용권이 필요해요</p>
        </div>
        <div class="desc">
            <p>이용 기간 동안 회원 수에 관계없이</p>
            <p><span class="spoqa-f-bold">무제한으로</span> 대화를 나눌 수 있어요.</p>
        </div>
        <div class="items m-t-24">
            <div
                class="item"
                v-for="item in items"
                :key="item.id"
                :class="{ selected: item.selected }"
                @click="onClickItem(item)"
            >
                <div v-if="item.is_best" class="best-seller" v-html="`최다 판매`" />
                <div class="left">
                    <img :src="require('@/assets/images/store/message_ticket.png')" alt="" />
                    <div class="item-name m-l-8 f-15 c-black" v-html="parseItemName(item.name)" />
                </div>
                <div
                    class="item-price spoqa-f-regular"
                    :class="{ 'm-t-3': item.is_best }"
                    v-html="`₩ ${item.price.toLocaleString()}`"
                />
            </div>
        </div>
        <BottomButton @click="onClickCta" :nonFixed="true" :label="`구매하고 대화하기`" />
        <p class="center f-12 spoqa-f-regular m-t-8 c-grey-06">* 모든 상품은 부가세 별도입니다.</p>
    </div>
</template>

<script>
import productService from '@/services/product'

export default {
    name: 'ModalInAppStore',
    methods: {
        parseItemName(name) {
            const splitName = name.split(' ')
            const purpleStr = `<span class='c-primary m-r-3 f-bold'>${splitName[0]}</span>`

            return purpleStr + splitName[1] + ` ${splitName[2]}`
        },
        onClickItem(item) {
            this.items = this.items.map(i => ({
                ...i,
                selected: i.id === item.id,
            }))
        },
        onClickCta() {
            const selected = this.items.find(item => item.selected)
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Try_Purchase',
                    option: {
                        product: selected.code,
                    },
                },
            })
            this.purchaseInapp(selected)
            this.$emit('close')
            // this.$modal.custom({ component: 'ModalSelectPurchaseMethod' }).then(button => {
            //     switch (button) {
            //         case 'purchaseInapp':
            //             this.purchaseInapp(selected)
            //             this.$emit('close')
            //             break
            //         case 'purchaseVirtualAccount':
            //             try {
            //                 this.purchaseVirtualAccount(selected)
            //             } catch (e) {
            //                 console.log(e)
            //             } finally {
            //                 this.$emit('close')
            //             }
            //             break
            //     }
            // })
        },
        async purchaseVirtualAccount(item) {
            const gender = this.$store.getters.me.gender === 0 ? 'male' : 'female'
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Try_Purchase_by_virtual',
                    option: {
                        product: item.code,
                    },
                },
            })
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `purchase_${gender}`,
                    option: {
                        type: 'custom',
                        value: item.price,
                    },
                },
            })
            this.$nativeBridge.postMessage({
                action: 'sendPurchaseEvents',
                value: {
                    gender: gender,
                    item: item,
                },
            })
            try {
                const data = await productService.createOrder(item.id, item)
                this.$stackRouter.push({
                    name: 'VirtualAccountPage',
                    props: { userVirtualAccount: data.user_virtual_account },
                })
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        purchaseInapp(item) {
            const gender = this.$store.getters.me.gender === 0 ? 'male' : 'female'
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Try_Purchase_by_inapp',
                    option: {
                        product: item.code,
                    },
                },
            })
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `purchase_${gender}`,
                    option: {
                        type: 'custom',
                        value: item.price,
                    },
                },
            })
            this.$nativeBridge.postMessage({
                action: 'sendPurchaseEvents',
                value: {
                    gender: gender,
                    item: item,
                },
            })

            this.$store.dispatch('requestInAppPurchase', item)
        },
    },
    data: () => ({
        items: [],
    }),
    props: ['options'],
    async created() {
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'ChatDetailPage_Alert_MessageTicket',
                option: {
                    target_user_id: this.options.targetId,
                },
            },
        })
        const { message_ticket: ticket } = await productService.all()
        this.items = ticket
        this.items = this.items.map(item => ({
            ...item,
            selected: item.is_best,
        }))
    },
}
</script>

<style scoped lang="scss">
.modal-basic {
    width: calc(100vw - 40px);
    padding-bottom: 16px;
    .head {
        text-align: center;
        font-size: 20px;
        color: black;
        line-height: 28px;
        padding-top: 16px;
    }
    .desc {
        text-align: center;
        color: $grey-09;
        font-size: 14px;
        line-height: 20px;
        @include spoqa-f-regular;
    }
    .items {
        .item {
            &.selected {
                border: 1px solid $purple-primary;
            }
            .best-seller {
                position: absolute;
                right: 10px;
                top: -5px;
                background-image: linear-gradient(108deg, #ba90ff, #8a74ff);
                border-radius: 11px;
                padding: 2px 10px;
                color: white;
                font-size: 12px;
                width: fit-content;
                @include f-medium;
            }
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 18px 16px;
            border-radius: 12px;
            box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
            margin-bottom: 10px;

            .left {
                display: flex;
                align-items: center;
            }
        }
    }
    ::v-deep .bottom-button {
        height: 44px;
        margin-top: 24px;

        button {
            height: 100%;
        }
    }
}
</style>
